<template>
  <div v-if="order">
    <div class="info">
      <PrintBlock
        :order="order"
        @buttonClick="closeWindow"
        @print="printLabels"
      />
    </div>
    <div
      v-for="(label, index) in labels"
      :key="index"
      id="print"
      class="label-generator"
    >
      <div class="label-generator-wrapper">
        <div class="label-generator__code" data-e2e="order-label-pickup-code">
          {{ order.pickupCode }}
        </div>
        <p v-if="isCustomerLastNameVisible" data-e2e="order-label-last-name">
          {{ order.customer.lastName }}
        </p>
        <div class="label-generator__qr" :data-e2e="`order-label-qr-${index}`">
          <QRcode
            :data="getQrData(order.pickupCode, label)"
            @loaded="onQrLoad"
          />
        </div>
        <div class="label-generator__datetime">
          <span class="label-generator__time" data-e2e="order-label-time">{{
            orderTime
          }}</span>
          <span class="label-generator__date" data-e2e="order-label-date">{{
            orderDate
          }}</span>
        </div>
        <div class="label-generator__table">
          <div class="note-list">
            <div class="item">
              <div class="item__title">
                {{ $t('pages.label-print-label-printer.list-item-bags.text') }}
              </div>
              <div
                class="item__description"
                :data-e2e="`order-label-bags-${index}`"
              >
                <div v-if="label.storageZone">
                  <span>
                    {{
                      getTranslation(
                        'pages.label-print-label-printer.list-item-bags-summary.text',
                        {
                          bagNumber: label.bagNumber,
                          storageZoneTotal: label.storageZone.total,
                        },
                      )
                    }}
                    {{ label.storageZone.name }}
                  </span>
                  <br />
                </div>
                <span>
                  {{
                    getTranslation(
                      'pages.label-print-label-printer.list-item-label-summary.text',
                      {
                        labelIndex: index + 1,
                        labelsCount: labels.length,
                      },
                    )
                  }}
                </span>
              </div>
            </div>
            <div class="item">
              <div class="item__title">
                {{
                  $t('pages.label-print-label-printer.list-item-order-id.text')
                }}
              </div>
              <div class="item__description" data-e2e="order-label-id">
                {{ order.orderReference }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { $t } from '@/i18n';
import { useOrder } from '@/features/picking';
import { configurationServicePlugin } from '@/features/configuration';
import { formatHours, formatDate } from '@/composables/useDateFormat';
import { QRcode } from '@/features/ui/components';
import { getQrData } from '../helpers/printLabel';
import { useOrderLabels } from '../composables';
import { PrintBlock } from '../components';

export default defineComponent({
  name: 'LabelPrinter',
  components: { PrintBlock, QRcode },

  setup() {
    const { order, loadOrder } = useOrder();
    const { params } = useRoute();
    const { labels } = useOrderLabels(order);

    const printLabels = () => {
      if (process.env.VUE_APP_CI === undefined) {
        window.print();
      }
    };

    const closeWindow = () => window.close();

    onMounted(async () => {
      await loadOrder(params.id as string);
    });

    const orderTime = computed(() =>
      formatHours(new Date(String(order.value?.startTime))),
    );
    const orderDate = computed(() =>
      formatDate(new Date(String(order.value?.startTime))),
    );

    let qrsLoadedAmount = 0;

    const onQrLoad = () => {
      if (++qrsLoadedAmount === labels.value.length) {
        printLabels();
      }
    };

    const getTranslation = (key: string, named = {}): string => {
      return $t(key, named);
    };

    // #region Customer Last Name on label
    const isCustomerLastNameVisible = ref(false);
    const configurationService = configurationServicePlugin.get();
    onMounted(async () => {
      isCustomerLastNameVisible.value =
        await configurationService.getFeatureOption(
          'printingStorageLabels',
          'customerLastNameOnLabel',
          'boolean',
        );
    });
    // #endregion

    return {
      labels,
      order,
      getQrData,
      orderTime,
      orderDate,
      onQrLoad,
      printLabels,
      closeWindow,
      getTranslation,
      isCustomerLastNameVisible,
    };
  },
});
</script>

<style lang="scss" scoped>
.label-generator {
  display: none;
}

@media print {
  .info {
    display: none;
  }
  @page {
    size: 101mm 152mm;
    margin: 0;
  }
  .label-generator {
    page-break-inside: avoid;
    display: flex;
    justify-content: center;
    width: 100%;
    max-height: 100%;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.53em;
    letter-spacing: 0.015em;
  }
  .label-generator-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .label-generator__code {
    display: flex;
    justify-content: center;
    margin-top: 14px;
    width: 100%;
    font-size: 36px;
    flex-direction: column;
    line-height: 1.22em;
  }
  .label-generator__qr {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
  }
  .label-generator__datetime span {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }
  .label-generator__time {
    position: relative;
    display: flex;
    width: 100%;
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.5px;
  }
  .label-generator__date {
    position: relative;
    display: flex;
    width: 100%;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
  }
  .note-list {
    padding: 0 16px;
    margin-top: 25px;
    text-align: left;
  }
  .item {
    display: grid;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    grid-template-columns: 96px 142px;
  }
  .item:last-child {
    border-bottom: 0;
  }
  .item__title {
    font-size: 11px;
    font-weight: 400;
    line-height: 1.45em;
  }
}
</style>
